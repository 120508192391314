import React, {lazy, Suspense, useEffect} from 'react';
import {Spin} from 'antd';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ENV, IS_PROD} from 'classfunc-helpers/env';
// import FormViewer from './components/Forms/FormViewer/FormViewer';
// import FormList from './components/Forms/FormList';
// import FormBuilder from './components/Forms/builder/FormBuilder';
// const FormList = lazy(() => import('./components/Forms/FormList'));
const FormListDrawer = lazy(() => import('./components/Forms/FormListDrawer'));
const FormBuilder = lazy(
    () => import('./components/Forms/builder/FormBuilder'));
const FormViewer = lazy(
    () => import('./components/Forms/FormViewer/FormViewer'));

function App() {
    useEffect(() => {
        document.title = IS_PROD
            ? document.title
            : `[${ENV}] ${document.title}`;
    }, []);
    return (
        <Suspense fallback={<Spin/>}>
            <BrowserRouter>
                <Routes>
                    <Route path={'/'} element={<FormListDrawer/>}/>
                    <Route path={'/forms/:id/edit'} element={<FormBuilder/>}/>
                    <Route path={'/embed/:formId'}
                           element={<FormViewer/>}/>
                </Routes>
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
